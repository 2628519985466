import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import LatestProjects from "../components/LatestProjects";

const IndexPage = () => (
  <Layout>
    <SEO title=" " />
    <section className="intro">
      <h1>Hey 👋</h1>
      <p>
        I'm Charlie, I'm a senior research engineer in cybersecurity, creative
        technologist and author of a{" "}
        <a
          href="https://www.apress.com/gp/book/9781484264171"
          target="_blank"
          rel="noopener noreferrer"
        >
          book about Tensorflow.js
        </a>
        !
      </p>
      <p>
        On my spare time, I explore the field of human-computer interaction
        (HCI) and build interactive prototypes using hardware, machine learning,
        creative coding, etc...
      </p>
      <p>
        I also regularly speak at conferences and write blog posts to share the
        things I learn.
      </p>
      <p>
        This portfolio gathers a collection of my side projects and talks. You
        can also find me on{" "}
        <a
          href="https://twitter.com/devdevcharlie"
          target="_blank"
          rel="noreferrer noopener"
        >
          Twitter
        </a>{" "}
        or{" "}
        <a
          href="https://github.com/charliegerard"
          target="_blank"
          rel="noreferrer noopener"
        >
          Github
        </a>
        , or subscribe to my{" "}
        <a
          href="https://buttondown.com/devdevcharlie"
          target="_blank"
          rel="noreferrer noopener"
        >
          weekly newsletter
        </a>{" "}
        about creative technology!
      </p>
    </section>
    <LatestProjects />
  </Layout>
);

export default IndexPage;
